@tailwind base;
@tailwind components;
@tailwind utilities;

.food-style-select input {
   box-shadow: none !important;
}

.__react_modal_image__icon_menu {
   margin-top: 13px;
   padding-right: 10px;
}